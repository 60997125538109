import React from "react"
import { Helmet } from "react-helmet"
import Aurora from "../components/Aurora/Aurora"
import Nav from "../components/Nav/Nav"
import Footer from "../components/Footer/Footer"

export default function Layout({ children }) {
  return (
    <div id="app">
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />

        <meta property="og:title" content="Blokhaus" />
        <meta property="og:description" content="Blokhaus is a marketing and communications agency, moving at the speed of blockchain and Web3."></meta>
        <meta property="og:url" content="https://blokhaus.io/" />
        <meta property="og:image" content="https://blokhaus.io/favicon/metaimage_blokhaus.jpg" />

        <meta name="twitter:title" content="Blokhaus" />
        <meta name="twitter:description" content="Blokhaus is a marketing and communications agency, moving at the speed of blockchain and Web3." />
        <meta name="twitter:image" content="https://blokhaus.io/favicon/metaimage_blokhaus.jpg" />

        <meta name="description" content="https://blokhaus.io/favicon/metaimage_blokhaus.jpg" />
        <link rel="canonical" href="https://blokhaus.io/" />
      </Helmet>
      <Aurora />
      <Nav />
      <main className={`relative`} >
        {children}
      </main>
      <Footer />
    </div>
  )
}